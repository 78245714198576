<template>
    <div class="">
        <!-- <header-page :header="headers"></header-page> -->

        <v-container>
            <v-row class="mx-auto">
                <v-col v-if="loaderdata.single === false">
                    <v-card tile flat class="px-4 mb-4">
                        <v-card-title>
                            <v-icon @click="backToPrevious" class="mr-10" color="secondary">{{ icons.mdiKeyboardBackspace }}</v-icon>
                            <v-spacer></v-spacer>
                            <v-card-subtitle class="text-md pa-0">
                                <v-icon size="13" class="secondary--text mr-1">{{ icons.mdiAlertCircle }}</v-icon>
                                <span class="secondary--text">Published at: {{ jobitem.created_at }}</span>
                            </v-card-subtitle>
                        </v-card-title>
                        <v-card-title>
                            <span>{{ jobitem.title }}</span>
                        </v-card-title>
                        <v-card-subtitle>
                            <div>
                                <v-icon small class="mr-1">{{ icons.mdiScatterPlot }}</v-icon>
                                <span>Domain: </span>
                                <span>{{ jobitem.domain }}</span>
                            </div>
                            <div>
                                <v-icon small class="mr-1">{{ icons.mdiMapMarkerOutline }}</v-icon>
                                <span>Location: </span>
                                <span>{{ jobitem.location }}</span>
                            </div>
                            <div>
                                <v-icon small class="mr-1">{{ icons.mdiTargetAccount }}</v-icon>
                                <span>Term duration: </span>
                                <span> {{ jobitem.duration }}</span>
                            </div>
                            <!-- update internal vacancies attributes -->
                            <!-- <div>
                                <v-icon small class="mr-1">{{ icons.mdiTargetAccount }}</v-icon>
                                <span>Language: </span>
                                <span> {{ jobitem.joblang }}</span>
                            </div> -->

                            <!-- update internal vacancies attributes -->
                            <!-- <div>
                                <v-icon>{{ icons.mdiBriefcase }}</v-icon>
                                {{ jobitem.periodicity }}
                                <span> {{ jobitem.joblang }}</span>
                            </div> -->
                        </v-card-subtitle>
                        <v-card-text class="d-flex align-center">
                            <v-avatar size="45" class="primary mr-4" tile>
                            </v-avatar>
                            <div class="mx-4 d-bloc">
                                <h3>
                                    <span class="primary--text">
                                        {{ jobitem.company.name }}
                                    </span>
                                </h3>
                                <!-- <a :href="jobitem.website" target="_blank">{{ jobitem.website }}</a> -->
                            </div>
                            <v-spacer></v-spacer>
                            <v-icon size="13" class="error--text mr-1">{{ icons.mdiCloseCircle }}</v-icon>
                            <!-- update internal vacancies attributes -->
                            <!-- <span class="error--text">Expiring on: {{ jobitem.expire_at }}</span> -->
                            <span class="error--text">Expiring on: {{ jobitem.dateline }}</span>
                        </v-card-text>
                        <v-card-actions class="pt-0" outlined>
                            <v-spacer></v-spacer>
                            <!-- <check-role-dialogs
                :actions="{ type: reoi.enterprise.type.libele, actionText: 'Reply proposal', link: 'proposal.reply', role: 'rfp_reply', title: reoi.title, k: reoi.id }">
              </check-role-dialog> -->

                            <check-role-dialog
                                :actions="{ type: jobitem.company.type.libele, actionText: 'Apply', link: 'vacancies.reply', role: 'job_apply', title: jobitem.title, k: jobitem.id }">
                            </check-role-dialog>
                        </v-card-actions>
                        
                    </v-card>
                    <v-card tile class="mb-4">
                        <v-card-text cols="12" class="">
                            <v-divider class="mt-4 mb-6"></v-divider>
                            <v-card elevation="0" tile>
                                <v-card-title class="subaccent">Job description
                                
                                </v-card-title>
                                <v-divider></v-divider>
                                <v-card-title>{{ jobitem.title }}</v-card-title>
                                <v-card-text v-html="jobitem.description.trim()">
                                </v-card-text>
                            </v-card>
                        </v-card-text>
                        <v-card-text cols="12">
                            <v-card-subtitle class="subaccent">
                            </v-card-subtitle>
                        </v-card-text>
                        
                    </v-card>
                    <detail-about-company :company="jobitem.company"></detail-about-company>
                </v-col>
                <v-col cols="12" v-else>
                    <v-skeleton-loader v-bind="attrs" type="card-avatar, article, actions"></v-skeleton-loader>
                </v-col>

            </v-row>

            <v-row class="mx-auto">
                <v-col v-if="loaderdata.similar === false">
                    <v-card tile>
                        <div class="secondary secondaryAccent--text pa-4">
                            <span>
                                Offres d’emploi similaires
                            </span>
                            <span class="text-caption">{{ no_resultitem }} résultats</span>
                        </div>
                        <!-- <template > -->
                            <!-- <vacancies-item-card :item="item" @click="selectedjob(item)"></vacancies-item-card> -->
                            <vacancies-item-card v-for="(item, index) in vacanciesItems" :item="item" :key="index"></vacancies-item-card>
                            <!-- <v-divider></v-divider> -->
                        <!-- </template> -->
                    </v-card>
                </v-col>
                <v-col v-else>
                    <v-skeleton-loader class="mx-auto pb-2" max-width="100%" type="list-item-two-line"
                        v-for="(i, j) in 5" :key="j">
                    </v-skeleton-loader>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
// mdiVideoInputComponent
import { mdiMagnify, mdiTargetAccount, mdiMapMarkerOutline, mdiScatterPlot, mdiCloseCircle, mdiTrashCan, mdiAlertCircle, mdiClose, mdiHelpCircleOutline, mdiOpenInNew, mdiKeyboardBackspace, mdiAlertOutline, mdiBriefcase } from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'
import { Utils } from '@/plugins/Utils';
import HeaderSearchPage from '@/views/components/HeaderSearchPage.vue'
import HeaderPage from '@/views/pages/components/HeaderPage.vue'
import VacanciesItemCard from '@/views/components/vacancies/VacanciesItemCard.vue';
import DetailAboutCompany from "@/views/components/company/DetailAboutCompany.vue"
import CheckRoleDialog from '@/views/components/CheckRoleDialog.vue';
import { async } from 'q';
export default {
    components: {
        HeaderSearchPage,
        HeaderPage,
        VacanciesItemCard,
        DetailAboutCompany,
        CheckRoleDialog
    },
    data() {
        return {
            no_resultitem: 0,
            searchDetails: '',
            loadSearchResult: false,
            loaderdata: {
                single: false,
                similar: false,
                all: false
            },
            submit: true,
            jobitem: {},
            vacancieslist: [],
            headersSearch: {
                title: "Tops relevants jobs",
                subtitle: "Choose from over the most relevants jobs we have selected for you.",
                icon: '',
                search: {
                    first: {
                        text: 'Search by offer',
                        icon: mdiMagnify
                    },
                    second: {
                        text: 'Search by place',
                        icon: mdiMapMarkerOutline
                    },
                }
            },
            headers: {
                titlepage: 'Job details',
                icon: this.icons.mdiMagnify
            },
            attrs: {
                class: 'mb-6',
                boilerplate: true,
                elevation: 2,
            },
        }
    },
    computed: {
        vacanciesItems() {
            return this.vacancieslist
        },
    },
    methods: {
        backToPrevious() {
            this.$router.go(-1)
        },
        openTab(link_ref) {
            window.open(link_ref, '_blank');
        },
        closeSearch() {
            this.loaderdata.all = true
            this.vacancieslist = []
            this.loadInitialJob()
        },
        searchActions(data) {
            this.loadSearchResult = true
            this.loaderdata.all = true
            const mainInputText = data.title.isEmpty === false ? data.title.value : ''
            const subInputText = data.subtitle.isEmpty === false ? data.subtitle.value : ''
            const domainInputtext = data.domain.isEmpty === false ? data.domain.value : ''

            this.vacancieslist = []
            Drequest.api(`job.search?offer_title=${mainInputText}&joblocation=${subInputText}`)
                .get((response) => {
                    if (response.success === true) {
                        response.listEntity.forEach((value, index) => {
                            this.vacancieslist.push(value)
                        });
                        this.jobitem = response.listEntity[0]
                        this.searchDetails = response.detail
                        this.no_resultitem = response.nb_element
                        this.loadSearchResult = false
                        this.loaderdata.all = false
                    }
                    else {
                        this.loadSearchResult = false
                        this.searchDetails = response.detail
                        this.loaderdata.all = false
                        this.no_resultitem = 0
                    }

                })
                .catch((err) => {
                    this.loadSearchResult = false
                    this.loaderdata.all = false

                    this.no_resultitem = 0
                });
        },
        async selectedjob(value) {
            this.$router.push({ name: 'vacancies.details', params: { offer_id: value.id, offer_title: Utils.replaceAll(value.title, ' ', '-') } })
            this.jobitem = value
        },
        async loadSimilarsJobs() {
            // Implementation avenir
            this.loaderdata.similar = true
            Drequest.api(`lazyloading.job?dfilters=on&job.id:neq=${this.$route.params.offer_id}&next=1&per_page=4`)
                .get((response) => {
                    if (response.success === true) {
                        response.listEntity.forEach((value, index) => {
                            this.vacancieslist.push(value)
                        });
                        this.loaderdata.similar = false
                        this.no_resultitem = this.vacancieslist.length
                    }
                    else {
                        this.loaderdata.similar = false
                        this.no_resultitem = 0
                    }
                })
                .catch((err) => {
                    this.loaderdata.similar = false
                    this.no_resultitem = 0
                });
        },
        async singleJob() {
            this.loaderdata.single = true
            Drequest.api(`lazyloading.job?dfilters=on&job.id:eq=${this.$route.params.offer_id}&next=1&per_page=1`)
                .get((response) => {
                    if (response.success === true) {
                        this.jobitem = response.listEntity[0]
                        console.log(this.jobitem)
                        this.loaderdata.single = false
                    }
                    else {
                        this.loaderdata.single = false
                    }
                })
                .catch((err) => {
                    this.loaderdata.single = false
                });
        }
    },
    watch: {
        '$route'(to, from) {
            this.singleJob()
        }
    },
    beforeMount() {
        this.singleJob()
        this.loadSimilarsJobs()
    },

    setup(props) {
        const icons = { mdiMagnify, mdiTargetAccount, mdiMapMarkerOutline, mdiScatterPlot, mdiCloseCircle, mdiTrashCan, mdiAlertCircle, mdiClose, mdiHelpCircleOutline, mdiOpenInNew, mdiKeyboardBackspace, mdiAlertOutline, mdiBriefcase }

        return {
            icons
        }
    }
}
</script>

<style lang="scss" scoped>
.bordo-l {
    border-top-left-radius: 0.8em !important;
}

.bordo-r {
    border-top-right-radius: 0.8em !important;
}

.bord-top-lr {
    border-top-right-radius: 0.8em !important;
    border-top-left-radius: 0.8em !important;
}
</style>