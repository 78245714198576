import { render, staticRenderFns } from "./VacanciesDetails.vue?vue&type=template&id=b0e3dae2&scoped=true&"
import script from "./VacanciesDetails.vue?vue&type=script&lang=js&"
export * from "./VacanciesDetails.vue?vue&type=script&lang=js&"
import style0 from "./VacanciesDetails.vue?vue&type=style&index=0&id=b0e3dae2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0e3dae2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAvatar,VCard,VCardActions,VCardSubtitle,VCardText,VCardTitle,VCol,VContainer,VDivider,VIcon,VRow,VSkeletonLoader,VSpacer})
